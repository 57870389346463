/* COLOR SCHEME (dark to light)
#03256C
#2541B2
#1768AC
#06BEE1
#FFFFFF
. */

/* @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
#home-row {
  /* margin-top: 120px; */
}

* {
  /* font-family: "Josefin Sans", sans-serif; */
  /* font-family: 'Play', sans-serif; */
  font-family: 'Chakra Petch', sans-serif;
}



#rankedTeam {
  border: 3px solid #03256c;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  padding-left: 0px;
  /* height: %; */
}

#rankedButton {
  border: 3px solid #03256c;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: white;
  color: black;
  width: 25%;
}

#rankNum {
  /* width: 10%; */
  background-color: #03256c;
  color: white;
  border-radius: 5px;
  /* padding-right: 20px; */
  /* padding: "1100px"; */
  text-align: center;
  justify-content: center;
}

#rankRecord {
  /* width: 10%; */
  background-color: #03256c;
  color: white;
  border-radius: 5px;
  /* padding-right: 20px; */
  /* padding: "1100px"; */
  text-align: center;
  justify-content: center;
}

#option {
  background-color: white;
  border: 5px solid #03256c;
  color: #282c34;
  margin: 10px;
  border-radius: 10px;
}

#card {
  margin: 1%;
}

#pContainer {
}

#option:hover {
  background-color: #cfddfa;
}

#button-addon2 {
  background-color: white;
  color: #03256c;
  border: 1px solid #03256c;
}

#button-addon2:hover {
  background-color: #03256c;
  color: white;
}

#weekBtn,
#weekBtnW {
  background-color: white;
  color: #03256c;
  border: 1px solid #2541b2;
}

#weekBtn:hover {
  background-color: #2541b2;
  color: white;
}

#weekBtnW:hover {
  color: white;
  background-color: #03256c;
}

#homeText {
  color: #03256c;
  font-size: 35px;
  font-weight: bold;
  /* text-decoration: underline; */
}

#weekBtn {
}

#optionText {
  margin-top: 35px;
  text-decoration: none;
  color: #03256c;
  font-size: 35px;
}

#optionText:hover {
  /* font-weight: bolder; */
  /* color: #06bee1; */
}

#keepersTable {
  margin-top: 2%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#tabTitle {
  font-size: 30px;
}

#login_with_google_btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  margin-bottom: 15px;
}
#login_with_google_btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

#login_with_google_btn:active {
  background-color: #eeeeee;
}

#login_with_google_btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

#login_with_google_btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

#header {
  background-color: white;
  border-bottom: #282c34 solid;
  border-width: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#log-out {
  border-style: none;
  color: red;
  background-color: white;
}

#log-out:hover {
  /* font-weight: bolder; */
}

#log-in {
  border-style: none;
  color: #03256c;
  font-size: 200%;
}

#log-in:hover {
  /* font-weight: bolder; */
}

#name-head {
  text-decoration: none;
  color: black;
}

#name-head:hover {
  /* font-weight: bolder; */
}

#team-pick {
  background-color: grey;
  border-radius: 0px;
  border-color: #282c34;
  border: 2px solid black;
  margin: 0px;
  padding: 0px;
  margin-top: 0px;
}

#benchRow {
  background-color: grey;
  height: 10%;
}

#kb {
  background-color: white;
  color: black;
  border-radius: 0px;
  /* padding-left:0px;
  padding-right:50%; */
  width: 80%;
  height: 100%;
  border-color: white;
}
#kb:hover {
  /* background-color: #dcdcfa; */
}

#no-but1 {
  background-color: white;
  color: black;
  border-radius: 0px;
  /* padding-left:0px;
  padding-right:50%; */
  width: 100%;
  height: 100%;
  border-color: white;
}
#no-but2 {
  background-color: white;
  color: black;
  border-radius: 0px;
  /* padding-left:0px;
  padding-right:50%; */
  width: 100%;
  height: 100%;
  border-color: white;
}
#infoB {
  background-color: white;
  color: black;
  border-radius: 0px;
  /* padding-left:0px;
  padding-right:50%; */
  width: 100%;
  height: 100%;
  border-color: white;
}

#no-but1:hover {
  /* background-color: #dcdcfa; */
}
#no-but2:hover {
  /* background-color: #dcdcfa; */
}
#infoB:hover {
  /* background-color: #dcdcfa; */
}

table {
  width: 90%;
}

th {
  font-size: 16px;
  text-align: left;
  justify-content: left;
  /* text-align:start; */
}

td {
  font-size: 12px;
  text-align: left;
}

.rightOption {
  font-size: 12px;
}

#rightPlayer {
  text-align: right;
}

#tbl,
#tbr {
  width: 50%;
  /* background-color: #03256c; */
}

#tbl {
  margin-left: 10%;
  /* background-color: yellow; */
}

#tbr {
  margin-right: 10%;
  /* background-color: red; */
}

#tbHeader {
  margin-top: 10px;
  font-size: 30px;
}

#tbBody {
  font-size: 20px;
}

#range {
  width: 50%;
}

.home-select {
  width: 100%;
}

#miss {
  font-size: 16px;
  color: grey;
}

#hit {
  font-size: 26px;
  font-weight: bolder;
  border-radius: 5px;
  border: white solid 5px;
  padding: 0px;
  margin-left: 10%;
  margin-right: 10%;
}

#random-button {
  background-color: white;
  color: black;
  border-radius: 0px;
  border-width: 3px;
  /* padding-left:0px;
  padding-right:50%; */
  width: 100%;
  height: 100%;
  border-color: #03256c;
}

#random-button:hover {
  background-color: #ffe8e6;
}

#random-button:active {
  background-color: #03256c;
  color: white;
}

#lock-in {
  background-color: white;
  color: black;
  border-radius: 0px;
  border-width: 3px;
  /* padding-left:0px;
  padding-right:50%; */
  width: 100%;
  height: 100%;
  border-color: #03256c;
}

#lock-in:hover {
  background-color: #ffe8e6;
}

#lock-in:active {
  background-color: #03256c;
  color: white;
}

#slot {
  margin-top: 50px;
}

/* #pickCont {
  margin-top:   10px;
} */

#kTitle {
  font-weight: bolder;
  font-size: 20px;
}

#lockRow {
  margin-top: 50x;
}

#select-button {
  margin-top: 10px;
  background-color: white;
  color: black;
  border-radius: 0px;
  border-width: 3px;
  /* padding-left:0px;
  padding-right:50%; */
  width: 60%;
  height: 100%;
  border-color: #2541b2;
}

#select-button:hover {
  background-color: #ffe8e6;
}

#TCSubmit {
  margin-top: 10px;
  background-color: white;
  color: black;
  border-radius: 0px;
  border-width: 3px;
  /* padding-left:0px;
  padding-right:50%; */
  width: 40%;
  border-color: #03256c;
  margin-bottom: 10px;
}

#TCSubmit:hover {
  background-color: #ffe8e6;
}

#TCSubmit:active {
  background-color: #03256c;
}

#horDiv {
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: lightgray;
  height: 3px;
  border-radius: 10px;
}

#tab,
#tabContainer {
  justify-content: center;
  text-align: center;
  align-content: center;
}

ul.nav.nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}

#pieColL,
#pieColR {
  width: 5%;
  height: 5%;
  padding: 5%;
  padding-top: 2%;
  font-size: 100px;
}

#pieTitle {
  font-size: 16px;
  font-weight: bolder;
  margin-top: 20px;
}

#pieT1 {
  color: red;
  /* float: bottom; */
}

#pieT2 {
  color: blue;
}

#picksTable {
  margin-bottom: 50px;
}

#SWTBMessage {
  font-size: 30px;
}

#todoAlert {
  /* width: 80%; */
  margin-top: 20px;
}

#suggest {
  bottom: 0;
  position: fixed;
  width: 80%;
  /* z-index: 150; */
  /* _position: absolute; */
  /* height: 35px; */
  /* margin-top: 40%; */
}

/* #tabContainer {
  justify-content: center;
} */

#rPicksTable,
#fPicksTable {
  table-layout: fixed;
  /* font-weight: ; */
}

@media (max-width: 850px) {
  #tbl {
    margin-left: 0%;
  }
  #tbr {
    margin-right: 0%;
  }
  #no-but1,
  #no-but2 {
    font-size: 12px;
  }
  #hit {
    font-size: 16px;
    margin-left: 2%;
    margin-right: 2%;
  }
  #miss {
    font-size: 10px;
    margin-left: 2%;
    margin-right: 2%;
  }
  #kb {
    font-size: 14px;
  }
  td {
    font-size: 10px;
  }
  #pieColL {
    padding-left: 0%;
  }
  #pieColR {
    padding-right: 0%;
  }
  #picksTable {
    /* font-size: 100px; */
  }
  #SWTBMessage {
    font-size: 14px;
  }
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
}

@media (max-width: 575px) {

  #kb {
    width: 100%;
  }

  #suggest {
    width: 100%;
    /* z-index: 150; */
    /* _position: absolute; */
    /* height: 35px; */
    /* margin-top: 40%; */
  }
  #horDiv {
    margin-left: 5%;
    margin-right: 5%;
  }
  #log-in {
    font-size: 150%;
  }
  #pieTitle {
    font-size: 12px;
  }
  #weekBtn,
  #weekBtnW {
    padding: 2px;
  }
  #rankRecord {
    font-size: 14px;
  }
  #rankedButton {
    width: 50%;
  }

  /* CSS that should be displayed if width is equal to or less than 800px goes here */
}
